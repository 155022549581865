import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import Modal from './Modal';
import logo from './assets/logo.png'; // Ajusta la ruta según sea necesario

const App = () => {
  const [curp, setCurp] = useState("");
  const [results, setResults] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const getOficios = () => {
    Axios.get(`https://cte.cieb-tam.net:5000/curp/${curp}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length === 0) {
            setError("No se Encontró el CURP. Comunicate al telefono 834-110-1821. Área de Informatica.");
          } else {
            setResults(response.data);
            setModalOpen(true);
            setError(null);
          }
        } else {
          setError(`Error fetching data: ${response.status} ${response.statusText}`);
          console.error(`API Error: ${response.status} ${response.statusText}`);
        }
      })
      .catch((error) => {
        setError("Hubo un error al obtener los datos. Intente de nuevo.");
        console.error("There was an error fetching the data!", error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (curp.trim() === "") {
      setError("Por favor, ingresa un CURP válido.");
      return;
    }
    getOficios();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <h2>Participación en Consejo Técnico Escolar de los Asesores de Inglés</h2>
      <form onSubmit={handleSubmit}>
        <h6>Ingresa tu CURP:</h6>
        <input
          type="text"
          id="curp"
          value={curp}
          onChange={(e) => setCurp(e.target.value)}
          required
        />
        <button type="submit">Consultar</button>
      </form>
      {error && <p className="error">{error}</p>}
      {modalOpen && <Modal results={results} closeModal={closeModal} />}
    </div>
  );
};

export default App;