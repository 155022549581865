import React, { useState } from 'react';
import './Modal.css';
import './App.css';

const Modal = ({ results, closeModal }) => {
  const [showMessage, setShowMessage] = useState(false); // Estado para controlar la visibilidad del mensaje de agradecimiento

  const handleCloseModal = () => {
    setShowMessage(true); // Mostrar el mensaje al hacer clic en "Enterado!"
    setTimeout(() => {
      setShowMessage(false); // Ocultar el mensaje después de 3 segundos (3000 milisegundos)
      closeModal(); // Cierra el modal normalmente
    }, 400); // Tiempo en milisegundos, ajusta según sea necesario
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h4>COORDINACIÓN DE INGLÉS</h4>
        </div>
        <div><br></br><h4>ESCUELA ASIGNADA PARA TOMAR LAS SESIONES ORDINARIAS DEL CONSEJO TÉCNICO ESCOLAR</h4></div>
        <div className="modal-body">
          <div className="table-container">
            <table>
              <tbody>
                <tr>
                  <th>ASESOR DE INGLÉS</th>
                  <td><b>José Alberto Báez García</b></td>
                </tr>
                <tr>
                  <th>ADSCRITO A:</th>
                  <td><b>Supervisión de Inglés en Educación Primaria Sede Tampico</b></td>
                </tr>
              </tbody>
            </table>
            <p>Se le notifica que, por instrucciones de la Coordinación de Inglés, durante el <b>ciclo escolar 2024 – 2025</b>, su carga horaria será:</p>
            <table>
              <thead>
                <tr>
                  <th>CCT</th>
                  <th>NOMBRE ESCUELA</th>
                  <th>TURNO</th>
                  <th>HRS</th>
                </tr>
              </thead>
              <tbody>
                {results.map((item, index) => (
                  <tr key={index}>
                    <td>{item.cct}</td>
                    <td>Profa. Juana Maria Cabrera Salinas</td>
                    <td>{item.ex2}</td>
                    <td>{item.paga}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {results.length > 0 && (
            <p><h5>Con base en lo anterior, durante el ciclo escolar, deberá participar en las sesiones Ordinarias del Consejo Técnico Escolar, así como en el Taller Intensivo de Formación Continua para Docentes en la <b>Escuela Profa. Juana Maria Cabrera Salinas</b> con CCT <b>{results[0].cct}</b> Turno <b>Matutino</b>.</h5></p>
          )}
        </div>
        <div className="modal-footer">
          {showMessage && ( // Mostrar el mensaje de agradecimiento si showMessage es true
            <div className="message">
              <p>¡Gracias por revisar la información!</p>
            </div>
          )}
          <button type="button" className="btn btn-primary btn-sm" onClick={handlePrint}>
            Imprimir
          </button>
          <button type="button" className="btn btn-primary btn-sm" onClick={handleCloseModal}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
